<template>
  <div>
    <v-layout py-12 wrap justify-center>
      <v-flex lg6 xl4 px-2>
        <v-card>
          <PageLoader v-bind:storage="appLoading" v-bind:contain="true" />
          <ServerError v-if="ServerError" />
          <v-layout py-12 wrap justify-center>
            <v-flex xs12>
              <v-img
                src="./../../../assets/success.png"
                height="80px"
                contain
                alt="Success"
              ></v-img>
            </v-flex>
            <v-flex xs12 pt-4 style="line-height: 20px">
              <span
                style="
                  font-family: opensansbold;
                  font-size: 25px;
                  color: #238cf8;
                "
              >
                Product {{ status }}
              </span>
              <br />
              <span
                style="
                  font-family: opensansbold;
                  font-size: 25px;
                  color: #fcc63d;
                "
              >
                Successfully
              </span>
            </v-flex>
            <v-flex xs12 sm8 pt-4>
              <v-layout wrap justify-center>
                <template v-for="(item, i) in productData.photos">
                  <v-flex xs12 :key="item" v-if="i == 0">
                    <v-img :src="mediaUURL + item" alt="Photos" height="150px" contain>
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="#FF2323"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-flex>
                  <v-flex xs4 :key="i" v-else pt-2>
                    <v-img :src="mediaURL + item" alt="Photos" height="80px" contain>
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="#FF2323"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-flex>
                </template>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm8 pt-4>
              <span
                style="
                  font-family: opensansbold;
                  font-size: 14px;
                  color: #909090;
                "
              >
                {{ productData.productname }}
              </span>
            </v-flex>
            <v-flex xs12>
              <span
                style="
                  font-family: opensanssemibold;
                  font-size: 14px;
                  color: #909090;
                "
              >
                Available Stock: {{ productData.stockquantity }}
              </span>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["status", "id"],
  data() {
    return {
      appLoading: false,
      timeout: 5000,
      msg: "",
      ServerError: false,
      productData: {},
    };
  },
  mounted() {
    this.getData();
    setTimeout(() => this.$router.push("/Inventory"), 5000);
    // setTimeout(() => this.$router.go(-2), 5000);
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/product/viewStore",
        params: {
          productId: this.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.productData = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>